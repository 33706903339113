<template>
  <select-base
    v-model="value"
    :disabled="disabled"
    :require="require_"
    :multiple="false"
    :label-select="'cadastroFederal'"
    :carregar="getOpcoesEmpresas"
    :filtros="filtros"
    @input="enviarParaPai"
  />
</template>

<script>
import api from '@/app/empresas/shared/services/api'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  name: 'MatrizSelect',
  components: {
    SelectBase: () => import('@app/shared/components/select-base/SelectBase.vue'),
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    require: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => { },
    },
    raizCadastroFederal: {
      type: String,
      default: '',
    },
    idEmpresaAtual: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      filtros: [
        {
          coluna: 'cnpjMatriz',
          valor: '',
        },
      ],
    }
  },
  computed: {
    require_: {
      get() {
        return this.require
      },
    },
  },
  methods: {
    getOpcoesEmpresas() { // carregarMatrizes
      return api.getEmpresasRaizCadastroFederal(this.idEmpresaAtual, this.raizCadastroFederal)
    },
    enviarParaPai() {
      this.$emit('input', this.value)
    },
  },
}
</script>
